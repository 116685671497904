table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  overflow-y: auto;
}

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  padding: 15px;
}

th {
  position: sticky;
  top: 0;
  text-align: left;
  font-weight: 600;
  font-size: 1.1rem;
  color: #202124;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: #202124;
  border-bottom: 1px solid #ccc;
}

tr:hover td {
  background-color: #e3e3e3;
}