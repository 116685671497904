.Challenge {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;
}

.table-container {
    border: 1px solid #ccc;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    margin: 40px;
    background-color: white;
}

.back-button {
    border: 1px solid #ccc;
    padding: 8px;
    position: absolute;
    bottom: 20px;
    left: 20px;    
}

.background-falcon {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}