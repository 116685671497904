.Dialog {
  position: absolute;
  background: #c0c0c08f;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.dialog-container {
  position: absolute;
  height: 500px;
  width: 700px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,
    rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border-radius: 20px 20px 0px 0px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dialog-header {
  border: 1px solid #ccc;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
  padding: 20px 10px;
  display: flex;
}

.dialog-body {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.dialog-item {
  display: flex;
  padding: 4px 8px;
}

.dialog-footer {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  padding: 20px;
}
