.falcon-line-primary {
  fill: #ea3d27;
}

.falcon-line-secondary {
  fill: #7b7f87;
}

@keyframes faded {
  0% {
    fill: #ea3d27;
  }
  50% {
    fill: rgb(26, 26, 26);
  }
  100% {
    fill: #ea3d27;
  }
}

.falcon-line-eyes {
  animation: faded 3s cubic-bezier(0.65, 0.05, 0.36, 1);
  fill: #ea3d27;
}
